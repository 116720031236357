<template>
  <div class="about">
    <!-- <h1>This is an about page</h1> -->

    <v-btn class="mx-2" fab dark small color="primary" />
    <h1>Shopping Cart Example</h1>
    <hr />
    <h2>Products</h2>
    <ProductList />
    <hr />
    <ShoppingCart />
  </div>
</template>

<script>
import ProductList from "./ProductList.vue";
import ShoppingCart from "./ShoppingCart.vue";
export default {
  components: { ProductList, ShoppingCart }
};
</script>
